import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Button from 'common-ui-components/Button';
import Api from 'global/api/platformApi';
import { isSuccessful } from 'global/api/platformApiHelpers';
import { Configuration } from 'model/Configuration';
import { ConfigType } from 'screens/backoffice/screens/configuration/utils/configurationUtils';
import DebuggerConsole from 'utils/DebuggerConsole';

import styles from 'screens/backoffice/screens/configuration/style.module.scss';

type ConfigValueProps = {
  config: Configuration;
  type: ConfigType;
  onDelete: () => void;
}

function getInitialValue(type: ConfigType, value: any) {
  switch (type) {
    case ConfigType.ARRAY:
      return value.toString().split(',').join(', ');
    case ConfigType.OBJECT:
      return JSON.stringify(value);
    default:
      return value.toString();
  }
}

export default function ConfigValue({ config, type, onDelete }: ConfigValueProps) {
  const initialValue = getInitialValue(type, config.value);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState<string>(initialValue);
  const [nextValue, setNextValue] = useState<string>(initialValue);
  const editRef = useRef<null | HTMLTextAreaElement>(null);
  const editable = type !== ConfigType.OBJECT;

  useEffect(() => {
    if (isEditing) {
      editRef.current?.select();
    }
  }, [isEditing]);

  function cancelValueChange() {
    setNextValue(currentValue);
    setIsEditing(false);
  }

  function getNextValue() {
    if (typeof config.value === 'string') {
      return nextValue.toString();
    }
    if (typeof config.value === 'boolean') {
      if (nextValue.toLowerCase().trim() === 'true') {
        return true;
      }
      if (nextValue.toLowerCase().trim() === 'false') {
        return false;
      }
      throw new Error('Value should be a boolean');
    } else if (typeof config.value === 'number') {
      if (/^[\d.]+$/.test(nextValue) && nextValue.split('.').length <= 1) {
        return Number(nextValue);
      }
      throw new Error('Value should be a number');
    } else if (config.value) {
      return nextValue.split(',').map((x) => x.trim());
    } else {
      throw new Error('Invalid type');
    }
  }

  async function setValue() {
    setIsEditing(false);
    setIsLoading(true);

    try {
      const nextConfig = { ...config, value: getNextValue() };
      const response = await Api.Backoffice.Config.setConfig(nextConfig);
      if (isSuccessful(response)) {
        setCurrentValue(nextValue);
      }
    } catch (err) {
      DebuggerConsole.error(err);
      setNextValue(currentValue);
    }
    setIsLoading(false);
  }

  async function handleDelete() {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const shouldDelete = confirm(`Are you sure you want to delete ${config.key}?`);
    if (shouldDelete) {
      try {
        const response = await Api.Backoffice.Config.deleteConfig(config);
        if (isSuccessful(response)) {
          onDelete();
        } else {
          DebuggerConsole.error(`Deleting ${config.key} failed on server side`);
        }
      } catch (err) {
        DebuggerConsole.error(err);
      }
    }
  }

  const StyledButton = (props) => <Button blue rounded padded {...props}>{props.children}</Button>;

  return (
    <div className={styles.configValueWrapper}>
      <div className={styles.configValue}>
        {
          isEditing
            ? (
              <div className={styles.valueEdit}>
                <textarea
                  className={type === ConfigType.ARRAY ? styles.list : ''}
                  ref={editRef}
                  value={nextValue}
                  onChange={(e) => setNextValue(e.target.value)}
                />
                <StyledButton onClick={setValue} disabled={isLoading}>
                  Save
                </StyledButton>
                <StyledButton onClick={cancelValueChange} disabled={isLoading}>
                  Cancel
                </StyledButton>
              </div>
            ) : (
              <div
                className={classNames(styles.currentValue, styles.ellipsis)}
                title={currentValue.toString()}
              >
                {currentValue.toString()}
              </div>
            )
        }
      </div>
      <div className={styles.configValueAction}>
        <StyledButton
          loading={isLoading}
          disabled={!editable}
          onClick={() => setIsEditing((prev) => !prev)}
          title={!editable && 'This config can only be edited from db'}
        >
          Edit
        </StyledButton>
        <StyledButton
          disabled={isLoading}
          onClick={handleDelete}
        >
          Delete
        </StyledButton>
      </div>
    </div>
  );
}

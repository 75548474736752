import { FC } from 'react';

import { ScreenKey } from 'global/ScreensConfiguration';
import BackofficeTenantRouter from 'routers/BackofficeRouter/BackofficeTenantRouter';
import AkoodaGPTScreen from 'screens/backoffice/screens/akoodaGPT/AkoodaGPTScreen';
import BackofficeConfigurationScreen from 'screens/backoffice/screens/configuration';
import BackofficeEmailAppScreen from 'screens/backoffice/screens/emailApp/BackOfficeEmailAppScreen';
import GPTSessionsScreen from 'screens/backoffice/screens/GPTSessions';
import BackofficeNudgesScreen from 'screens/backoffice/screens/nudges/BackofficeNudgesScreen';
import BackofficeOdsScreen from 'screens/backoffice/screens/ods/BackofficeOdsScreen';
import BackofficeOfflineScansScreen from 'screens/backoffice/screens/offlineScans/BackofficeOfflineScansScreen';
import BackofficeSuggestedTagsScreen from 'screens/backoffice/screens/suggestedTags/BackofficeSuggestedTagsScreen';

const backofficeScreens = [
  'tenant',
  'configuration',
  'ods',
  'offlineScans',
  'suggestedTags',
  'nudges',
  'akoodaGpt',
  'gptSessions',
  'emailApp', // This exists in order to support our demo for Google, so our Gmail app is approved
] as const;
type BackofficeScreen = (typeof backofficeScreens)[number];

interface BackofficeScreenConfig {
  label: string;
  screenKey: ScreenKey;
  Component: FC;
}

export enum BackOfficeScanRunningStatus { SUCCESS, FAILURE }

export const BackofficeScreensConfig:
  Readonly<Record<BackofficeScreen, BackofficeScreenConfig>> = Object.freeze({
    tenant: {
      label: 'Tenant',
      screenKey: 'backoffice.tenant',
      Component: BackofficeTenantRouter,
    },
    configuration: {
      label: 'Configuration',
      screenKey: 'backoffice.configuration',
      Component: BackofficeConfigurationScreen,
    },
    ods: {
      label: 'ODS',
      screenKey: 'backoffice.ods',
      Component: BackofficeOdsScreen,
    },
    offlineScans: {
      label: 'Offline Scans',
      screenKey: 'backoffice.offline-scans',
      Component: BackofficeOfflineScansScreen,
    },
    suggestedTags: {
      label: 'Suggested Tags',
      screenKey: 'backoffice.suggested-tags',
      Component: BackofficeSuggestedTagsScreen,
    },
    nudges: {
      label: 'Nudges',
      screenKey: 'backoffice.nudges',
      Component: BackofficeNudgesScreen,
    },
    akoodaGpt: {
      label: 'Akooda GPT',
      screenKey: 'backoffice.akooda-gpt',
      Component: AkoodaGPTScreen,
    },
    gptSessions: {
      label: 'GPT Sessions',
      screenKey: 'backoffice.gpt-sessions',
      Component: GPTSessionsScreen,
    },
    emailApp: {
      label: 'Email App',
      screenKey: 'backoffice.email-app',
      Component: BackofficeEmailAppScreen,
    },
  });

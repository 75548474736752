import ConfigurationFilterableProperty from 'global/api/controller/utils/filtering/ConfigurationFilterableProperty';
import useConfigurationFiltersConfig, {
  ConfigurationFilterableColumnId,
  ConfigurationFiltersConfigRawOptions,
  ConfigurationFilterValues,
} from 'screens/backoffice/screens/configuration/hooks/configurationFiltersConfigHook';
import { FiltersConfig } from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import useFiltersState from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/filtersStateHook';

export type ConfigurationsFiltersState = ReturnType<
  typeof useFiltersState<
    ConfigurationFilterableColumnId,
    ConfigurationFilterValues,
    ConfigurationFilterableProperty
  >
> & {
  configurationFiltersConfig: FiltersConfig<ConfigurationFilterableColumnId>;
};

export default function useConfigurationsFilters(
  availableColumns: ConfigurationFilterableColumnId[],
  rawOptions: ConfigurationFiltersConfigRawOptions,
): ConfigurationsFiltersState {
  const configurationFiltersConfig = useConfigurationFiltersConfig(rawOptions);
  const { filterConditions, ...filtersState } = useFiltersState<
    ConfigurationFilterableColumnId,
    ConfigurationFilterValues,
    ConfigurationFilterableProperty
  >(configurationFiltersConfig, availableColumns);

  return {
    filterConditions,
    configurationFiltersConfig,
    ...filtersState,
  };
}

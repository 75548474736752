import { useEffect, useState } from 'react';

import Api from 'global/api/platformApi';
import { AKOODA_TENANT_ID } from 'global/constants';
import { Configuration } from 'model/Configuration';
import DebuggerConsole from 'utils/DebuggerConsole';

export default function useConfigurationsScreenData() {
  const [allConfigs, setAllConfigs] = useState<null | Configuration[]>(null);
  const [
    akoodaEmployees,
    setAkoodaEmployees,
  ] = useState<null | { _id: string; name: string; id: string }[]>(null);

  useEffect(() => {
    async function fetchConfigs() {
      try {
        const allConfigsResponse = await Api.Backoffice.Config.getAllConfigurations();
        if (allConfigsResponse?.data) {
          setAllConfigs(allConfigsResponse.data);
        }
      } catch (err) {
        DebuggerConsole.error(err);
      }
    }
    fetchConfigs();
  }, []);

  useEffect(() => {
    async function fetchAkoodaEmployees() {
      try {
        const akoodaEmployeesResponse = await Api.Backoffice.Config
          .getTenantEmployees(AKOODA_TENANT_ID);
        if (akoodaEmployeesResponse?.data) {
          setAkoodaEmployees(akoodaEmployeesResponse.data);
        }
      } catch (err) {
        DebuggerConsole.error(err);
      }
    }
    fetchAkoodaEmployees();
  }, []);

  return {
    allConfigs,
    setAllConfigs,
    akoodaEmployees,
  };
}

import React, { Dispatch, SetStateAction, useMemo } from 'react';

import SearchableSelectionDropdown from 'common-ui-components/SearchableSelectionDropdown';

interface Props {
  selectedPersonId: string | null;
  setSelectedPersonId: Dispatch<SetStateAction<string | null>>;
  allEmployees: { _id: string; name: string; id: string }[];
}

export default function TenantEmployeeSelection({
  selectedPersonId, setSelectedPersonId, allEmployees,
}: Props) {
  const options = useMemo(() => [
    { value: null, label: 'All Tenant Employees' },
    ...allEmployees.map(({ _id, name, id }) => ({ value: _id, label: `${name} - ${id}` })),
  ], [allEmployees]);

  return (
    <SearchableSelectionDropdown<string | null>
      options={options}
      selectedValue={selectedPersonId}
      selectValue={setSelectedPersonId}
    />
  );
}

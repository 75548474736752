import React, { useState } from 'react';

import CopiableText from 'common-ui-components/CopiableText';
import Table from 'common-ui-components/Table';
import { SortingConfig } from 'common-ui-components/Table/sortingHook';
import { Configuration } from 'model/Configuration';
import ConfigValue from 'screens/backoffice/screens/configuration/components/ConfigValue';
import { ConfigurationsFiltersState } from 'screens/backoffice/screens/configuration/hooks/configurationsFiltersHook';
import { getValueType } from 'screens/backoffice/screens/configuration/utils/configurationUtils';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import StringUtils from 'utils/StringUtils';

import style from 'screens/backoffice/screens/configuration/style.module.scss';

interface ConfigurationsTableProps {
  allConfigs: Configuration[] | null;
  akoodaEmployees: { _id: string; name: string }[] | null;
  removeConfig: (configToRemove: Configuration) => void;
  filters: ConfigurationsFiltersState;
}

export enum ConfigurationsTablePropertyColumnId {
  TENANT = 'TENANT',
  PERSON = 'PERSON',
  KEY = 'KEY',
  TYPE = 'TYPE',
  VALUE = 'VALUE'
}

enum ConfigurationSortableField {
  TENANT = 'TENANT',
  PERSON = 'PERSON'
}

const defaultConfigurationsTableSorting: SortingConfig<ConfigurationSortableField> = {
  columnKey: ConfigurationSortableField.TENANT,
  order: 'ascend',
};

export default function ConfigurationsTable({
  allConfigs,
  akoodaEmployees,
  removeConfig,
  filters,
}: ConfigurationsTableProps) {
  const [sorting, setSorting] = useState<SortingConfig<ConfigurationSortableField>>(
    defaultConfigurationsTableSorting,
  );
  const { allTenants } = useTenantContext();
  const {
    filtersValues,
    setFiltersValues,
    configurationFiltersConfig,
  } = filters;

  return allTenants && akoodaEmployees ? (
    <Table<Configuration, ConfigurationSortableField>
      dataSource={allConfigs || []}
      rowKey="_id"
      loading={allConfigs === null}
      emptyDataMsg="No configurations"
      onSortingChange={(sorter) => setSorting(sorter as SortingConfig<ConfigurationSortableField>)}
      sorting={sorting}
      onFiltersChange={setFiltersValues}
      filtersValues={filtersValues}
      columns={[
        {
          title: 'Tenant',
          key: ConfigurationSortableField.TENANT,
          render: (_, config) => (
            <span className={style.ellipsis}>
              {!config._tid
                ? '-'
                : StringUtils.capitalize(allTenants.find((t) => t.id === config._tid)?.name ?? 'Unknown tenant')}
            </span>
          ),
          width: 130,
          sorting: {
            byComparator: (a, b) => {
              const tenantA = allTenants.find((t) => t.id === a._tid)?.name || '-';
              const tenantB = allTenants.find((t) => t.id === b._tid)?.name || '-';
              return StringUtils.alphabeticalDescendingSorter(tenantA, tenantB);
            },
          },
          filterConfig: configurationFiltersConfig.TENANT,
        }, {
          title: 'Person',
          key: ConfigurationSortableField.PERSON,
          render: (_, config) => (
            <span className={style.ellipsis}>
              {!config.personId
                ? '-'
                : StringUtils.capitalize(akoodaEmployees.find((p) => p._id === config.personId)?.name ?? 'Unknown Person')}
            </span>
          ),
          width: 130,
          sorting: {
            byComparator: (a, b) => {
              const tenantA = allTenants.find((t) => t.id === a._tid)?.name || '-';
              const tenantB = allTenants.find((t) => t.id === b._tid)?.name || '-';
              return StringUtils.alphabeticalDescendingSorter(tenantA, tenantB);
            },
          },
          filterConfig: configurationFiltersConfig.PERSON,
        }, {
          title: 'Key',
          key: 'Key',
          render: (_, config) => <CopiableText text={config.key} singleRow />,
          sorting: {
            byComparator: (a, b) => StringUtils.alphabeticalDescendingSorter(a.key, b.key),
          },
        }, {
          title: 'Type',
          key: 'Type',
          render: (_, config) => (
            <span className={style.ellipsis}>
              {getValueType(config.value)}
            </span>
          ),
          width: 100,
        }, {
          title: 'Value',
          key: 'Value',
          render: (_, config) => (
            <ConfigValue
              config={config}
              type={getValueType(config.value)}
              onDelete={() => removeConfig(config)}
            />
          ),
        },
      ]}
    />
  ) : null;
}

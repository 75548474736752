import React, { useCallback, useEffect, useState } from 'react';

import Button from 'common-ui-components/Button';
import SearchInput from 'common-ui-components/SearchInput';
import { satisfiesFilterCondition } from 'global/api/controller/utils/filtering/FilterCondition';
import { areConfigsIdentical, Configuration, NewlyCreatedConfiguration } from 'model/Configuration';
import AddConfigurationModal from 'screens/backoffice/screens/configuration/components/AddConfigurationModal';
import ConfigurationsTable, { ConfigurationsTablePropertyColumnId } from 'screens/backoffice/screens/configuration/components/ConfigurationsTable';
import { ConfigurationFilterableColumnModelKey } from 'screens/backoffice/screens/configuration/hooks/configurationFiltersConfigHook';
import useConfigurationsFilters from 'screens/backoffice/screens/configuration/hooks/configurationsFiltersHook';
import useConfigurationsScreenData from 'screens/backoffice/screens/configuration/hooks/configurationsScreenDataHook';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useDebouncedString } from 'utils/hooks';

import { ReactComponent as PlusIcon } from 'assets/img/icon/plus.svg';

import styles from 'screens/backoffice/screens/configuration/style.module.scss';
import backofficeStyles from 'screens/backoffice/style.module.scss';

export default function BackofficeConfigurationScreen() {
  const { allTenants } = useTenantContext();

  const [filteredConfigs, setFilteredConfigs] = useState<null | Configuration[]>(null);
  const [searchQuery, setSearchQuery] = useDebouncedString();
  const [isAddConfigurationModalOpen, setIsAddConfigurationModalOpen] = useState(false);
  const { allConfigs, setAllConfigs, akoodaEmployees } = useConfigurationsScreenData();
  const filters = useConfigurationsFilters(
    [
      ConfigurationsTablePropertyColumnId.TENANT,
      ConfigurationsTablePropertyColumnId.PERSON,
    ],
    {
      TENANT: allTenants,
      PERSON: akoodaEmployees,
    },
  );
  const { filterConditions } = filters;

  const filterConfigsBySearchQuery = (configs: Configuration[]) => (searchQuery === ''
    ? configs
    : configs.filter(
      (config) => config.key.toLowerCase()
        .includes(searchQuery.toLowerCase()),
    ));

  const filterConfigsByFilterConditions = (configs: Configuration[]) => configs.filter(
    (config) => Object.values(filterConditions)
      .every((condition) =>
        satisfiesFilterCondition(
          config[ConfigurationFilterableColumnModelKey[condition.fieldName]],
          condition,
        )),
  );

  useEffect(() => {
    if (allConfigs !== null) {
      setFilteredConfigs(
        filterConfigsByFilterConditions(filterConfigsBySearchQuery(allConfigs)),
      );
    }
  }, [allConfigs, searchQuery, filterConditions]);

  function removeConfig(configToRemove: Configuration) {
    setAllConfigs((prev) => (
      prev && prev.filter((c) => c._id !== configToRemove._id)
    ));
  }

  const saveNewConfig = useCallback((newConfig: NewlyCreatedConfiguration) => {
    setAllConfigs((prev) => (prev
      ? prev.reduce((acc, currConfig) => {
        if (!areConfigsIdentical(newConfig, currConfig)) {
          acc.push(currConfig);
        }
        return acc;
      }, [{ ...newConfig, _id: 'new-config-tmp-_id' }])
      : null));
  }, []);

  return allTenants ? (
    <div className={backofficeStyles.backofficeInternalScreen}>
      {
        isAddConfigurationModalOpen && (
          <AddConfigurationModal
            isOpen={isAddConfigurationModalOpen}
            setIsOpen={setIsAddConfigurationModalOpen}
            allConfigs={allConfigs}
            saveNewConfig={saveNewConfig}
            allAkoodaEmployees={akoodaEmployees ?? []}
          />
        )
      }
      <h2>
        Configurations
      </h2>
      <div className={backofficeStyles.headerSection}>
        <Button
          color="blue"
          rounded
          padded
          className={backofficeStyles.addButton}
          onClick={() => setIsAddConfigurationModalOpen(true)}
        >
          <PlusIcon color="white" width={15} height={15} />
          Add a new configuration
        </Button>
        <SearchInput setValue={setSearchQuery} placeholder="Search by configuration key" />
      </div>
      <div>
        <div className={styles.tableWrapper}>
          <ConfigurationsTable
            allConfigs={filteredConfigs}
            akoodaEmployees={akoodaEmployees}
            removeConfig={removeConfig}
            filters={filters}
          />
        </div>
      </div>
    </div>
  ) : null;
}

import classNames from 'classnames';
import React, { useState } from 'react';

import Button from 'common-ui-components/Button';
import Modal from 'common-ui-components/Modal';
import Api from 'global/api/platformApi';
import { ParsedTenant } from 'screens/backoffice/screens/tenant/BackofficeTenantScreen';

import backofficeStyles from 'screens/backoffice/style.module.scss';

interface AddTenantModalProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    allTenants: null | ParsedTenant[];
}

export default function AddTenantModal({ isOpen, setIsOpen, allTenants }: AddTenantModalProps) {
  const [tenantName, setTenantName] = useState<string>('');
  const [tenantEmailDomain, setTenantEmailDomain] = useState<string>('');
  const [bottomMessage, setBottomMessage] = useState<string | undefined>(undefined);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  function onSubmit() {
    setErrorOccurred(false);
    if (tenantName.length === 0 || tenantEmailDomain.length === 0) {
      setBottomMessage('empty parameters');
      setErrorOccurred(true);
      return;
    }
    if (allTenants?.find((t) => t.name === tenantName)) {
      setBottomMessage(`Tenant with name ${tenantName} already exist`);
      setErrorOccurred(true);
      return;
    }
    Api.Backoffice.Tenant.createTenant(tenantName.trim(), tenantEmailDomain.trim())
      .then((res) => setBottomMessage(res?.data))
      .catch((err) => {
        setBottomMessage(err.message);
        setErrorOccurred(true);
      });
  }

  return (
    <Modal
      className={backofficeStyles.backofficeModalContent}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <h2>Add a new tenant</h2>
      <span>
        Tenant name:
        <input
          value={tenantName}
          onChange={(event) => setTenantName(event.target.value)}
        />
      </span>
      <span>
        Email domain (identifier):
        <input
          value={tenantEmailDomain}
          onChange={(event) => setTenantEmailDomain(event.target.value)}
        />
      </span>
      <Button color="blue" padded rounded onClick={onSubmit}>
        Submit
      </Button>
      <div className={classNames({ 'error-message': errorOccurred })}>
        {bottomMessage}
      </div>
    </Modal>
  );
}

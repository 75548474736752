import { useMemo } from 'react';

import ConfigurationFilterableProperty from 'global/api/controller/utils/filtering/ConfigurationFilterableProperty';
import { FilterFieldType } from 'global/api/controller/utils/filtering/FilterCondition';
import { IdFilterOperator } from 'global/api/controller/utils/filtering/IdFilterCondition';
import { ListOptions } from 'global/ListOptions';
import Tenant from 'model/Tenant';
import { ConfigurationsTablePropertyColumnId } from 'screens/backoffice/screens/configuration/components/ConfigurationsTable';
import FilterConfig, {
  DynamicListOptions,
  FiltersConfig,
} from 'screens/platform/directory/components/GenericDirectoryScreen/FiltersList/FilterConfig';
import StringUtils from 'utils/StringUtils';

export type ConfigurationFilterableColumnId =
  | ConfigurationsTablePropertyColumnId.TENANT
  | ConfigurationsTablePropertyColumnId.PERSON

export enum ConfigurationFilterableColumnModelKey {
  TENANT = '_tid',
  PERSON = 'personId',
}

export type ConfigurationFilterValues = Partial<{
  [ConfigurationsTablePropertyColumnId.TENANT]: string[];
  [ConfigurationsTablePropertyColumnId.PERSON]: string[];
}>;

export type ConfigurationFiltersConfigRawOptions = {
  [ConfigurationsTablePropertyColumnId.TENANT]: Tenant[] | undefined;
  [ConfigurationsTablePropertyColumnId.PERSON]: {
      _id: string;
      name: string;
      id: string;
  }[] | null;
}

const useTenantFilterConfig = (tenants: ConfigurationFiltersConfigRawOptions['TENANT']): FilterConfig<string> => {
  const options: ListOptions<string> = (tenants || []).map(({ id, name }) => ({
    value: String(id),
    label: StringUtils.capitalize(name),
  }));

  return {
    label: 'Tenant',
    options: new DynamicListOptions(options),
    multiSelect: true,
    removable: true,
    defaultValue: [],
    toFilterCondition: (value) => ({
      fieldName: ConfigurationFilterableProperty.TENANT,
      fieldType: FilterFieldType.ID,
      operator: IdFilterOperator.IS_ANY_OF,
      value,
    }),
  };
};

const usePersonFilterConfig = (people: ConfigurationFiltersConfigRawOptions['PERSON']): FilterConfig<string> => {
  const options: ListOptions<string> = (people || []).map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));

  return {
    label: 'Person',
    options: new DynamicListOptions(options),
    multiSelect: true,
    removable: true,
    defaultValue: [],
    toFilterCondition: (value) => ({
      fieldName: ConfigurationFilterableProperty.PERSON,
      fieldType: FilterFieldType.ID,
      operator: IdFilterOperator.IS_ANY_OF,
      value,
    }),
  };
};

const useConfigurationFiltersConfig = (
  rawOptions: ConfigurationFiltersConfigRawOptions,
): FiltersConfig<ConfigurationFilterableColumnId> => {
  const tenantFilterConfig = useTenantFilterConfig(rawOptions.TENANT);
  const personFilterConfig = usePersonFilterConfig(rawOptions.PERSON);

  return useMemo(
    () => ({
      [ConfigurationsTablePropertyColumnId.TENANT]: tenantFilterConfig,
      [ConfigurationsTablePropertyColumnId.PERSON]: personFilterConfig,
    }),
    [rawOptions.PERSON],
  );
};

export default useConfigurationFiltersConfig;
